// Pour le moment pas une priorité... on verra par la suite !!!

import { createSlice } from "@reduxjs/toolkit";

// Les noms et les choix de représentation des datas ne sont pas toujours judicieux et il y a des redondances ...
// Mais le modèle globale est au final efficace et laisse la possibilité à des ajouts futurs (stats à la semaine par ex, cycles d'entrainement)
// donc on le laisse tel quel car les modifications seront lourdes à faire...

// Le state est de la forme :
// {
//     Id (coureur): {
//         coureur: 'David Gaudu',
//         week_idx: ['2015-12', '2015-13', ...],
//         weeks: {
//             '2015-12': {days: [{date_str: "2024-06-03", day_id: 0, day_str: "3 Juin", numeros: [...], ... }, ...]},
//             '2015-13': {days: [...]},
//             '2015-14': {days: [...]},
//              ...
//         }
//     },
//     ...
// }


/////////////////////////////////////////////// Reducers ///////////////////////////////////////////////


// Ajout des semaines d'un coureur
// action = {Id, coureur, week_idx : [...], weeks: {...}}
const addWeeks = (state, action) => {
    let Id = action.payload.Id
    let old_weeks = (Object.keys(state).includes(Id)) ? state[Id].weeks : {} // Json vide si on ajoute le coureur
    let old_weeks_idx = (Object.keys(state).includes(Id)) ? state[Id].week_idx : [] // Json vide si on ajoute le coureur

    state[Id] = {
        coureur: action.payload.coureur, // On remet le coureur à chaque fois
        week_idx: [...new Set([...old_weeks_idx, ...action.payload.week_idx])].sort(), // On redonne à chaque fois la liste (triée) des semaines présentes dans le store
        weeks: { ...old_weeks, ...action.payload.weeks } // Les semaines ajoutées sont nécessairement nouvelles
    }
}



// Ajout de plusieurs activités
// action = {numero: {week, day, Id}, ...}
const addPlanifActivities = (state, action) => {
    for (const [numero, dico] of Object.entries(action.payload)) {
        let week = dico['week']
        let day = dico['day']
        let Id = dico['Id']
        // console.log(week, day,)
        if (state.hasOwnProperty(Id)) {
            if (state[Id].weeks.hasOwnProperty(week)) {
                state[Id].weeks[week].days[day].numeros.push(parseInt(numero))
            }
        }
    }
}



// Suppression de plusieurs activités
// action = {activities: {numero: {datas act}}, numsToDel: [...] )}
const delPlanifActivities = (state, action) => {
    let activities = action.payload.activities

    for (const numero of action.payload.numsToDel) {
        let Id = activities[numero].Id
        let week = activities[numero].week
        let day = activities[numero].day
        // console.log(Id, week, day, typeof numero)
        const newNumeros = state[Id].weeks[week].days[day].numeros.filter(num => num !== numero)
        state[Id].weeks[week].days[day].numeros = newNumeros
    }
}


// Update ou ajout des stats d'une week
// Se fait généralement après la mise à jour d'une semaine
const updateWeekStats = (state, action) => {
    let week = action.payload.week
    let Id = action.payload.Id

    // On s'assure que la semaine du coureur est bien dans le store
    if (state?.[Id]?.weeks?.[week] !== undefined) {
        state[Id].weeks[week].stats_week = action.payload.week_stats
    }
}


/////////////////////////////////////////////// PlanifSlice ///////////////////////////////////////////////

export const PlanifSlice = createSlice({
    name: 'planif',
    initialState: {},
    reducers: {
        // addWeeks: (state, action) => {
        //     let Id = action.payload.Id
        //     let old_weeks = (Object.keys(state).includes(Id)) ? state[Id].weeks : {} // Json vide si on ajoute le coureur
        //     return {
        //         ...state,
        //         [Id]: {
        //             coureur: action.payload.coureur, // On remet le coureur à chaque fois
        //             week_idx: action.payload.week_idx, // On redonne à chaque fois la liste (triée) des semaines présentes dans le store
        //             weeks: { ...old_weeks, ...action.payload.weeks } // Les semaines ajoutées sont nécessairement nouvelles
        //         }
        //     }
        // },
        addWeeks: addWeeks,
        delPlanifActivities: delPlanifActivities,
        addPlanifActivities: addPlanifActivities,
        updateWeekStats: updateWeekStats,
    }
})



/////////////////////////////////////////////// Selectors ///////////////////////////////////////////////


export const getDayNumerosActs = (state, Id, week_idx, day_id) => {
    // console.log(state.planif[Id])

    // Après un axios pour avoir les data de planification d'un coureur le store n'est pas updated instantanément et state.planif[Id]
    // est undefined, donc il faut gérer ce cas en attendant l'update (qui prend 1/10ème de seconde), sinon on a un plantage
    if (state.planif.hasOwnProperty(Id)) {
        // console.log(state.planif[Id].weeks)
        return state.planif[Id].weeks[week_idx].days[day_id].numeros
    } else {
        return []
    }
    // return state.planif[Id].weeks[week_idx].days[day_id].numeros

}


export const getWeeksInStore1C = (state, coureur) => {
    let Id = state.usersInfos.users_Ids[coureur]

    if (state.planif.hasOwnProperty(Id)) {
        // console.log(state.planif[Id].weeks)
        return state.planif[Id].week_idx
    } else {
        return []
    }
}


export const getNumsActivitiesOfWeek1C = (state, Id, week) => {
    if (state.planif.hasOwnProperty(Id)) {
        let days = state.planif[Id].weeks[week].days
        let numeros = []
        for (let day of days) {
            numeros = numeros.concat(day.numeros)
        }
        return numeros
    } else {
        return []
    }
}


// Retourne les stats de la semaine donnée
export const getStatsWeek = (state, coureur, week_id) => {
    let Id = state.usersInfos.users_Ids[coureur]
    return state.planif?.[Id]?.weeks?.[week_id]?.stats_week
}

