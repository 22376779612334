// Coureur de la page sur lequel on est

import { createSlice } from "@reduxjs/toolkit";



export const UsersInfosSlice = createSlice({
    name: 'usersInfos',
    initialState: {},
    reducers: {
        updateUsersInfos: (state, action) => action.payload
    }
})


export const getUsersInfos = (state) => state.usersInfos
export const getUsersIds = (state) => state.usersInfos.users_Ids
export const getUsersFonction = (state) => state.usersInfos.users_fonctions

export const getUserFromId = (state, Id) => state.usersInfos.Ids_users[Id]
export const getIdFromUser = (state, user) => state.usersInfos.users_Ids[user]
export const getCoureursFTP = (state, coureur) => state.usersInfos.coureurs_ftp?.[coureur]


export const getCoureursWT = (state) => state.usersInfos.world_tour
export const getCoureursConti = (state) => state.usersInfos.conti
export const getCoureursJuniors = (state) => state.usersInfos.juniors
export const getCoureurs = (state) => state.usersInfos.world_tour.concat(state.usersInfos.conti).concat(state.usersInfos.juniors)
export const getCoachs = (state) => state.usersInfos.coachs

// Les mêmes avec "Tous" devant
export const getTousCoureursWT = (state) => ['Tous'].concat(getCoureursWT(state))
export const getTousCoureursConti = (state) => ['Tous'].concat(getCoureursConti(state))
export const getTousCoureursJuniors = (state) => ['Tous'].concat(getCoureursJuniors(state))
export const getTousCoureurs = (state) => ['Tous'].concat(getCoureurs(state))
export const getTousCoachs = (state) => ['Tous'].concat(getCoachs(state))


export const getTeamsCoureurs = (state) => {
    return {
        'World Tour': getCoureursWT(state),
        'Conti': getCoureursConti(state),
        'Juniors': getCoureursJuniors(state),
        'Tous': getCoureurs(state)
    }
}

export const getTeamsTousCoureurs = (state) => {
    return {
        'World Tour': getTousCoureursWT(state),
        'Conti': getTousCoureursConti(state),
        'Juniors': getTousCoureursJuniors(state),
        'Tous': getTousCoureurs(state)
    }
}



export const getCoachsCoureurs = (state) => {
    console.log(getCoureurs(state))
    let res = {
        'Tous': getCoureurs(state),
        ...state.usersInfos.coach_coureurs
    }
    return res
}

// La même avec 'Tous' devant chaque coach
export const getTousCoachsCoureurs = (state) => {
    let res = {
        'Tous': getCoureurs(state),
        ...state.usersInfos.coach_coureurs
    }
    for (const [key, value] of Object.entries(res)) {
        res[key] = ['Tous'].concat(value)
    }
    return res
}












